import React from "react";
import Slider from "react-slick";
import { Link } from "gatsby";
import * as styles from "./articles.module.css";
import { BsChevronDoubleRight } from "@react-icons/all-files/bs/BsChevronDoubleRight";
import { FaArrowLeft } from "@react-icons/all-files/fa/FaArrowLeft";
import { FaArrowRight } from "@react-icons/all-files/fa/FaArrowRight";
import { useStaticQuery, graphql } from "gatsby";

const settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1092,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const Articles = ({ sectionTitle, articles }) => {
  const defaultData = useStaticQuery(graphql`
    query ArticlesQuery {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { frontmatter: { layout: { eq: "post" } } }
        skip: 0
        limit: 5
      ) {
        edges {
          node {
            frontmatter {
              date
              slug
              title
              tags
              description
              og_image {
                publicURL
              }
              image
            }
          }
        }
      }
    }
  `);

  const updatedData = articles || defaultData;
  // const updatedData = articles;
  const posts = updatedData.allMdx.edges;
  const slider = React.useRef();
  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };

  return (
    <div className={styles.slider_main}>
      {/* <h3> STAY UP TO DATE</h3> */}
      <h2 className={styles.maintTitle}>
        {sectionTitle ? (
          sectionTitle
        ) : (
          <>
            Latest trending <span>articles</span>
          </>
        )}
      </h2>
      <div className={`flex items-center justify-center ${styles.slider_btn}`}>
        <a
          className="border border-purple-500 hover:border-purple-800 hover:bg-purple-500 hover:cursor-pointer p-2"
          onClick={previous}
        >
          <FaArrowLeft />
        </a>
        <a
          className="border border-purple-500 hover:border-purple-800 hover:bg-purple-500 hover:cursor-pointer p-2"
          onClick={next}
        >
          <FaArrowRight />
        </a>
      </div>

      <Slider ref={(c) => (slider.current = c)} {...settings}>
        {posts.map((post, index) => {
          const postData = post.node.frontmatter;

          return (
            <Link to={`/posts/${postData.slug}/`}>
              <div className={styles.slider_card} key={index}>
                <img
                  src={
                    postData.image
                      ? postData.image
                      : postData.og_image.publicURL
                  }
                  alt={postData.title}
                />

                <div className={styles.slider_cardcontent}>
                  <h4>{postData.date}</h4>
                  <h3>{postData.title}</h3>
                  <p>{postData.description}</p>
                  <span className={`flex items-center justify-left`}>
                    READ MORE <BsChevronDoubleRight />
                  </span>
                </div>
              </div>
            </Link>
          );
        })}
      </Slider>
      <div className={styles.slider_belowbtn1}>
        <Link
          to="/posts/"
          title="Blog"
          class="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-md px-5 py-2.5 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800"
        >
          <div className={`${styles.button4} px-6 py-1`}>
            <span>Read Latest Blog Posts</span>
            <div className={styles.back}></div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Articles;
